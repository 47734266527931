import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import {APP_STATE_BOOT_LOAD_SUCCESS} from '../../actions/app-actions';

export const DEFAULT_STATE = {
  assign_conversation_to_employee: false,
  customer_profile: false,
  desktop_thread_input_persistence_disabled: false,
  enable_business_messages: false,
  enable_whatsapp_admin: false,
  enable_channel_admin: false,
  enable_last_respondent_avatar_in_inbox: false,
  enable_multistore_conversation: false,
  enable_payment_feature: false,
  enable_ui_messenger_channel: false,
  enable_ui_whatsapp_channel: false,
  ip_ranges: false,
  notification: false,
  statistics_disable_menu: false,
  statistics_disable_menu_summary: false,
  statistics_disable_menu_top_stores: false,
  statistics_disable_menu_new_customers: false,
  statistics_disable_menu_preferred_channel: false,
  statistics_disable_menu_satisfaction: false,
  statistics_disable_menu_messages: false,
  statistics_disable_menu_devices: false,
  statistics_disable_menu_contact_centers: false,
  statistics_disable_menu_contact_centers_real_time: false,
  statistics_disable_menu_contact_centers_contacts: false,
  statistics_disable_menu_contact_centers_web_aht: false,
  statistics_disable_menu_contact_centers_employees: false,
  statistics_enable_response_rate: false,
  survey: false,
  thread_activity: false,
  transfer_back_in_scope: false,
  twilio_enable: false
};

export default createReducer(
  {},
  {
    /**
     * Set the features toggle only once the app is booted
     * (it's important, in order to avoid extra actions between authentication and the end of app initialization, like sockets handling).
     * Because backend returns keys only when enabled, we eventually add the ones we use in the app.
     * @param {Object} state
     * @param {Object} data
     * @returns {Object} new state
     */
    [APP_STATE_BOOT_LOAD_SUCCESS]: (state, {data}) =>
      fromJS({...DEFAULT_STATE, ...data.featureToggle})
  }
);
