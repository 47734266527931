import {davysGrey, mediumGreen, red} from '../../../../../../shared/style/colors';

const FACEBOOK_CHANNEL_LOGIN_CONFIGURATION = {
  appId: FACEBOOK_APP_ID,
  scope: 'pages_manage_metadata,pages_messaging,pages_show_list,business_management',
  version: 'v10.0'
};

const FACEBOOK_WHATSAPPP_SENDER_CONFIGURATION = {
  appId: FACEBOOK_WHATS_APP_SENDER_APP_ID,
  configId: FACEBOOK_WHATS_APP_SENDER_CONFIG_ID,
  solutionId: FACEBOOK_WHATS_APP_SENDER_SOLUTION_ID,
  scope: 'pages_messaging',
  version: 'v21.0'
};

const getChannelStatusConnectionColor = ({
  facebookChannelConnected,
  facebookChannelError,
  i18n
}) => {
  if (facebookChannelConnected) {
    return {
      switchButtonColor: mediumGreen,
      labelButton: i18n.t(
        'administration.business.pages.channels.facebook.itemLabelButtonConnected'
      )
    };
  }

  if (facebookChannelError) {
    return {
      switchButtonColor: red
    };
  }

  return {
    switchButtonColor: davysGrey,
    labelButton: i18n.t('administration.business.pages.channels.facebook.itemLabelButtonConnect')
  };
};

const isAvailableFacebookConfigurationScopeIntoFacebookGrantedScope = (facebookGrantedScope) => {
  return FACEBOOK_CHANNEL_LOGIN_CONFIGURATION.scope.indexOf(facebookGrantedScope);
};

export {
  FACEBOOK_CHANNEL_LOGIN_CONFIGURATION,
  FACEBOOK_WHATSAPPP_SENDER_CONFIGURATION,
  getChannelStatusConnectionColor,
  isAvailableFacebookConfigurationScopeIntoFacebookGrantedScope
};
