import {merge, of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {ofType} from 'redux-observable';
import handleErrors from '../../../../../../../shared/epic/lib/handle-errors';
import {
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_LOAD,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_LOAD_FAILURE,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_LOAD_SUCCESS,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_LAYOUT_MOUNTED,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAAPP_WABA_RETRIEVED,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_CREATE_FAILURE,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_CREATE_SUCCESS
} from '../../../../../actions/administration/administration-business-channels-whatsapp-actions';
import {
  graphqlGetWhatsAppSenderConnected,
  graphqlCreateWhatsAppSender
} from '../../graphql-queries/grapql-business-page-channels-whatsapp-query-helper';

const ERROR_MAPPING_CHANNEL_STATUS_CONNECTION = {
  40102: 'errorTokenNotValid',
  40104: 'errorTokenNotValid',
  40331: 'errorAdministrator'
};

const AdministrationBusinessPageChannelsWhatsAppEpic =
  ({graphql}) =>
  (action$, state$) => {
    const loadWhatsAppSenderStatusOnWhatsAppLayoutMounted = action$.pipe(
      ofType(ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_LAYOUT_MOUNTED),
      map(() => {
        return {
          type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_LOAD
        };
      })
    );

    const createWhatsappSender = action$.pipe(
      ofType(ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAAPP_WABA_RETRIEVED),
      mergeMap(({wabaId}) => {
        const businessId = state$.value.getIn([
          'administration',
          'layout',
          'currentPageContext',
          'businessId'
        ]);

        return graphql(graphqlCreateWhatsAppSender(businessId, wabaId)).pipe(
          map(({createBusinessWhatsappSenderAsAdmin}, errors) => {
            if (errors) {
              return {
                type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_CREATE_FAILURE,
                errors: handleErrors(errors, ERROR_MAPPING_CHANNEL_STATUS_CONNECTION)
              };
            }

            return {
              type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_CREATE_SUCCESS,
              createBusinessWhatsappSenderAsAdmin
            };
          }),
          catchError((error) => {
            return of({
              type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_CREATE_FAILURE,
              error
            });
          })
        );
      })
    );

    const loadWhatsappSenderStatus = action$.pipe(
      ofType(ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_LOAD),
      mergeMap(() => {
        const businessId = state$.value.getIn([
          'administration',
          'layout',
          'currentPageContext',
          'businessId'
        ]);

        return graphql(graphqlGetWhatsAppSenderConnected(businessId)).pipe(
          map(({businessWhatsappSenderAsAdmin}, errors) => {
            if (errors) {
              return {
                type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_LOAD_FAILURE,
                errors: handleErrors(errors, ERROR_MAPPING_CHANNEL_STATUS_CONNECTION)
              };
            }

            return {
              type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_LOAD_SUCCESS,
              businessWhatsappSenderAsAdmin
            };
          }),
          catchError((error) => {
            return of({
              type: ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_LOAD_FAILURE,
              error
            });
          })
        );
      })
    );

    return merge(
      loadWhatsAppSenderStatusOnWhatsAppLayoutMounted,
      loadWhatsappSenderStatus,
      createWhatsappSender
    );
  };

export default AdministrationBusinessPageChannelsWhatsAppEpic;
