import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import AdministrationBusinessPageChannelsFacebook from './channel/facebook/administration-business-page-channels-facebook-component';
import AdministrationBusinessPageChannelsWhatsApp from './channel/whatsapp/administration-business-page-channels-whatsapp-component';
import AdministrationBusinessPageChannelGbm from './channel/gbm/administration-business-page-channels-gbm-component';
import AdministrationBusinessPageChannelGbmBusinessLocationComponent from './channel/gbm/administration-business-page-channels-gbm-business-location-component';
import AdministrationBusinessPageChannelsGbmDialog from './channel/gbm/administration-business-page-channels-gbm-dialog-component';
import {red} from '../../../../../../../shared/style/colors';

const AdministrationBusinessPageChannelsComponent = ({
  business,
  enableBusinessMessages,
  enableWhatsAppAdmin,
  enableChannelAdmin,
  facebookChannel,
  businessWhatsappState,
  gbmBusinessState,
  gbmOrganizationState,
  isBusinessManager,
  isManager,
  i18n,
  doAdministrationBusinessPageChannelsOpen,
  doFacebookPageDisconnection,
  doFacebookPageConnection,
  doFacebookPageRestartDataToReconnection,
  doStoreFacebookChannelRetrievedToken,
  onAdministrationBusinessPageChannelsGbmMounted,
  onDisconnectGbmBusinesLocationButtonClick,
  onGbmBusinessLocationFormSubmitButtonClick,
  onAdministrationBusinessPageChannelsWhatsAppMounted,
  onAdministrationBusinessPageChannelsWhatsAppWabaRetrieved
}) => {
  const [gbmBusinessLocationOpen, setGbmBusinessLocationOpen] = useState(false);
  const [isSwitchButtonChecked, setIsSwitchButtonChecked] = useState(false);
  const [isDisconnected, setIsDisconnected] = useState(false);
  const [showGbmBusinessDisconnectionPopup, setShowGbmBusinessDisconnectionPopup] = useState(false);

  const isGbmBusinessDisconnected = (event) => !(!event.target.checked && isDisconnected);

  const onOpenBusinessLocation = (event) => {
    setIsSwitchButtonChecked(isGbmBusinessDisconnected(event));

    if (event.target.checked) {
      setIsSwitchButtonChecked(true);
      setGbmBusinessLocationOpen(true);
    } else {
      setShowGbmBusinessDisconnectionPopup(true);
    }
  };

  const onCloseBusinessLocation = () => {
    setGbmBusinessLocationOpen(false);
  };

  const onClickCancelDisconnectGbmBusiness = () => {
    setShowGbmBusinessDisconnectionPopup(false);
    setIsSwitchButtonChecked(true);
  };

  const onClickConfirmDisconnectGbmBusiness = () => {
    onDisconnectGbmBusinesLocationButtonClick();
  };

  const onSuccessDialog = () => {
    setShowGbmBusinessDisconnectionPopup(false);
    setIsSwitchButtonChecked(false);
    setIsDisconnected(true);
  };

  useEffect(() => {
    if (!showGbmBusinessDisconnectionPopup && isDisconnected) {
      onAdministrationBusinessPageChannelsGbmMounted();
    }
  }, [showGbmBusinessDisconnectionPopup, isDisconnected]);

  return (
    <React.Fragment>
      <AdministrationBusinessPageChannelsGbmDialog
        confirmColor={red}
        disconnectProps={gbmBusinessState.businessLocationDisconnectDialog}
        errorLabel={i18n.t('administration.business.pages.channels.gbm.errorDefaultMessage')}
        labels={i18n.t('administration.business.pages.channels.gbm.dialogConfirm', {
          returnObjects: true
        })}
        onClickCancel={onClickCancelDisconnectGbmBusiness}
        onClickConfirm={onClickConfirmDisconnectGbmBusiness}
        onSuccessDialog={onSuccessDialog}
        opened={showGbmBusinessDisconnectionPopup}
      />
      {gbmBusinessLocationOpen ? (
        <AdministrationBusinessPageChannelGbmBusinessLocationComponent
          {...{
            business,
            gbmBusinessState,
            i18n,
            onCloseBusinessLocation,
            onGbmBusinessLocationFormSubmitButtonClick
          }}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '698px',
            padding: '0 1em 2em 1em'
          }}
        >
          {enableBusinessMessages ? (
            <AdministrationBusinessPageChannelGbm
              onCheckSwitchButton={setIsSwitchButtonChecked}
              {...{
                gbmBusinessState,
                gbmOrganizationState,
                isSwitchButtonChecked,
                isBusinessManager,
                isManager,
                i18n,
                onAdministrationBusinessPageChannelsGbmMounted,
                onOpenBusinessLocation
              }}
            />
          ) : null}

          {enableChannelAdmin ? (
            <AdministrationBusinessPageChannelsFacebook
              {...{
                business,
                facebookChannel,
                i18n,
                doAdministrationBusinessPageChannelsOpen,
                doFacebookPageDisconnection,
                doFacebookPageConnection,
                doFacebookPageRestartDataToReconnection,
                doStoreFacebookChannelRetrievedToken
              }}
            />
          ) : null}

          {enableWhatsAppAdmin ? (
            <AdministrationBusinessPageChannelsWhatsApp
              {...{
                business,
                i18n,
                onAdministrationBusinessPageChannelsWhatsAppMounted,
                onAdministrationBusinessPageChannelsWhatsAppWabaRetrieved,
                businessWhatsappState
              }}
            />
          ) : null}
        </div>
      )}
    </React.Fragment>
  );
};

AdministrationBusinessPageChannelsComponent.propTypes = {
  business: PropTypes.objectOf(PropTypes.any).isRequired,
  enableBusinessMessages: PropTypes.bool.isRequired,
  enableWhatsAppAdmin: PropTypes.bool.isRequired,
  enableChannelAdmin: PropTypes.bool.isRequired,
  facebookChannel: PropTypes.objectOf(PropTypes.any),
  businessWhatsappState: PropTypes.objectOf(PropTypes.any).isRequired,
  gbmBusinessState: PropTypes.objectOf(PropTypes.any).isRequired,
  gbmOrganizationState: PropTypes.objectOf(PropTypes.any).isRequired,
  i18n: PropTypes.objectOf(PropTypes.any).isRequired,
  isBusinessManager: PropTypes.bool.isRequired,
  isManager: PropTypes.bool.isRequired,
  doAdministrationBusinessPageChannelsOpen: PropTypes.func.isRequired,
  doFacebookPageConnection: PropTypes.func.isRequired,
  doFacebookPageDisconnection: PropTypes.func.isRequired,
  doFacebookPageRestartDataToReconnection: PropTypes.func.isRequired,
  doStoreFacebookChannelRetrievedToken: PropTypes.func.isRequired,
  onAdministrationBusinessPageChannelsGbmMounted: PropTypes.func.isRequired,
  onDisconnectGbmBusinesLocationButtonClick: PropTypes.func.isRequired,
  onGbmBusinessLocationFormSubmitButtonClick: PropTypes.func.isRequired,
  onAdministrationBusinessPageChannelsWhatsAppMounted: PropTypes.func.isRequired,
  onAdministrationBusinessPageChannelsWhatsAppWabaRetrieved: PropTypes.func.isRequired
};

export default AdministrationBusinessPageChannelsComponent;
