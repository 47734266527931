import {jsonToGraphQLQuery} from 'json-to-graphql-query';

/**
 * Get query to load administration GBM business location.
 * @returns {String}
 */
const graphqlGetWhatsAppSenderConnected = (businessId) => {
  const query = {
    query: {
      businessWhatsappSenderAsAdmin: {
        status: true,
        details: {
          sid: true,
          senderId: true
        },
        __args: {
          businessId
        }
      }
    }
  };

  return jsonToGraphQLQuery(query);
};

const graphqlCreateWhatsAppSender = (businessId, wabaId) => {
  const mutation = {
    mutation: {
      createBusinessWhatsappSenderAsAdmin: {
        status: true,
        details: {
          sid: true,
          senderId: true
        },
        __args: {
          businessId,
          wabaId
        }
      }
    }
  };

  return jsonToGraphQLQuery(mutation);
};

export {graphqlGetWhatsAppSenderConnected, graphqlCreateWhatsAppSender};
