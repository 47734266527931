import {createReducer} from 'redux-immutablejs';
import {fromJS} from 'immutable';
import {
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_LOAD,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_LOAD_SUCCESS,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_LOAD_FAILURE,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_CREATE,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_CREATE_SUCCESS,
  ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_CREATE_FAILURE
} from '../../../../../actions/administration/administration-business-channels-whatsapp-actions';
import {APP_LOGOUT} from '../../../../../actions/app-actions';

export const DEFAULT_STATE = fromJS({
  sender: null,
  error: null,
  loading: true
});

const deleteWhatsAppSenderPersistedState = () => {
  return DEFAULT_STATE;
};

export default createReducer(DEFAULT_STATE, {
  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_LOAD]: (state) => {
    return state.set('loading', true);
  },

  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_LOAD_SUCCESS]: (
    state,
    {businessWhatsappSenderAsAdmin}
  ) => {
    return state.set('sender', fromJS(businessWhatsappSenderAsAdmin)).set('loading', false);
  },

  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_LOAD_FAILURE]: (state, {error}) => {
    return state.set('error', error).set('loading', false);
  },

  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_CREATE]: (state) => {
    return state.set('loading', true);
  },

  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_CREATE_SUCCESS]: (
    state,
    {createBusinessWhatsappSenderAsAdmin}
  ) => {
    return state.set('sender', fromJS(createBusinessWhatsappSenderAsAdmin)).set('loading', false);
  },

  [ADMINISTRATION_BUSINESS_PAGE_CHANNELS_WHATSAPP_SENDER_CREATE_FAILURE]: (state, {error}) => {
    return state.set('error', error).set('loading', false);
  },

  [APP_LOGOUT]: deleteWhatsAppSenderPersistedState
});
